import React from "react";
import './ShowRooms.css'
import '../../../css/material-icons.css'
class ShowRooms extends React.Component {
	componentDidMount() {
		window.scroll(0, 0)
	}
	render() {
		return (
			<div>
				{/* Start Section Showrooms */}
				<section className='showrooms'>
					<div className='showrooms__holder'>
						<div className='showrooms__wrapper col-md-6 col-md-offset-3'>
							<div
								className='secondry-title wow fadeInUp'
								data-wow-duration='0.3s'
								data-wow-delay='0.3s'
							>
								<h2>Online Showrooms </h2>
							</div>
							<div
								className='showrooms__caption wow fadeInUp'
								data-wow-duration='0.3s'
								data-wow-delay='0.3s'
							>
								<p>
									You don’t have to drive through traffic to find what you’re
									looking for. Browse any of our showrooms from the comfort of
									your home.{' '}
								</p>
							</div>
							<div className='showrooms_btn-holder wow fadeInDown'>
								<button
									type='button'
									onClick={() => {
										window.location.href =
											'https://mpembed.com/show/?m=zEJWuMeMu5P&mpu=497'
									}}
									className='showroom__btn-btn btn btn-default'
								>
									Spring 2024 New Collection
								</button>
							</div>
							<div className='showrooms_btn-holder wow fadeInDown'>
								<button
									type='button'
									onClick={() => {
										window.location.href =
											'https://mpembed.com/show/?m=MSdU5W8nzpy&mpu=497'
									}}
									className='showroom__btn-btn btn btn-default'
								>
									GEMMA @ CERSAIE SEP. 2023
								</button>
							</div>
							<div className='showrooms_btn-holder wow fadeInDown'>
								<button
									type='button'
									onClick={() => {
										window.location.href =
											'https://mpembed.com/show/?m=KmJ1baCpCAR&mpu=497'
									}}
									className='showroom__btn-btn btn btn-default'
								>
									Spring 2023 New Collection
								</button>
							</div>
							<div className='showrooms_btn-holder wow fadeInDown'>
								<button
									type='button'
									onClick={() => {
										window.location.href =
											'https://mpembed.com/show/?m=WFzC4fZZzsN&mpu=497'
									}}
									className='showroom__btn-btn btn btn-default'
								>
									Gemma@Cersaie Sep.2022
								</button>
							</div>
							<div className='showrooms_btn-holder wow fadeInDown'>
								<button
									type='button'
									onClick={() => {
										window.location.href =
											'https://mpembed.com/show/?m=v1pLEM5vQPh&mpu=497'
									}}
									className='showroom__btn-btn btn btn-default'
								>
									Autumn 2021 New Collection
								</button>
							</div>
						</div>
					</div>
				</section>
				{/* End Section Showrooms */}
			</div>
		)
	}
}
export default ShowRooms;