import React from "react";
import "./Catalogues.css";
import "../../css/bootstrap.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-accessible-accordion/dist/minimal-example.css";
import { getAllObjects } from "../../services/CommonServices";
import { Grid } from "react-bootstrap";

class FinancialReports extends React.Component {
  componentDidMount() {
    window.scroll(0, 0);
    getAllObjects("cms").then((res) => {
      this.setState({
        wall_url: res.data[0].wall_url,
        floor_url: res.data[0].floor_url,
        porcelain_url: res.data[0].porcelain_url,
        new_catalogue:
          "https://gemma-static.s3.eu-west-1.amazonaws.com/files/GEMMA+-+CATALOGUE+2023-Phase+II.pdf",
        catalogue_2024:
            "https://gemma-static.s3.eu-west-1.amazonaws.com/files/CATALOGUE+2024-Phase+I.pdf",
      });
    });
  }

  handleViewClick = (viewOption) => {
    let win;
    if (viewOption === "wall") {
      win = window.open(this.state.wall_url, "_blank");
      win.focus();
    } else if (viewOption === "floor") {
      win = window.open(this.state.floor_url, "_blank");
      win.focus();
    } else if (viewOption === "porcelain") {
      win = window.open(this.state.porcelain_url, "_blank");
      win.focus();
    } else if (viewOption === "new_catalogue") {
      win = window.open(this.state.new_catalogue, "_blank");
      win.focus();
    } else if (viewOption === "catalogue_2024") {
      win = window.open(this.state.catalogue_2024, "_blank");
      win.focus();
    }
  };
  render() {
    return (
      <div className="FinancialReports">
        <div className="wishlist__title ">
          <h2 className="main-line-title">Catalogues </h2>
        </div>
        <div className="catalogues-bg">
          <Grid style={{ margin: "auto" }}>
            <div className="Financial-Reports__content">
              <div className="catalogue-container">
                <button
                  onClick={() => this.handleViewClick("floor")}
                  type="button"
                  className="main-button collection__btn btn btn-default catalogue-btn"
                >
                  Floor Catalogue
                </button>
                <button
                  onClick={() => this.handleViewClick("wall")}
                  type="button"
                  className="main-button collection__btn btn btn-default catalogue-btn"
                >
                  Wall Catalogue
                </button>
              </div>
              <div className="catalogue-container">
                <button
                  onClick={() => this.handleViewClick("porcelain")}
                  type="button"
                  className="main-button collection__btn btn btn-default catalogue-btn"
                >
                  Porcelain Catalogue
                </button>
                <button
                  onClick={() => this.handleViewClick("new_catalogue")}
                  type="button"
                  className="main-button collection__btn btn btn-default catalogue-btn"
                >
                  2023 Catalogue
                </button>
                <button
                  onClick={() => this.handleViewClick("catalogue_2024")}
                  type="button"
                  className="main-button collection__btn btn btn-default catalogue-btn"
                >
                  2024 Catalogue
                </button>
              </div>
            </div>
          </Grid>
        </div>
      </div>
    );
  }
}
export default FinancialReports;
